<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import { parseFieldSpecs } from "@fullcalendar/core";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      totalDebtors: [],
      billsPayable: [],
      total: [],
      feesTotal: 0,
      transactionsTotal: 0,
      remainTotal: 0,
    };
  },
  methods: {
    getDebtors() {
      this.http.post("billing-invoices/bills-payable", {}).then((res) => {
        this.billsPayable = res.data;
        const self = this;

        this.billsPayable.forEach((bill) => {
          self.feesTotal += bill.fees;
          self.transactionsTotal += bill.transactionTot;
          self.remainTotal += bill.remain;
        });
      });
    },
  },
  created() {
    this.getDebtors();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('bills_payable.title')"
    />
    <table class="table m-0" :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
      <thead>
        <tr class="text-center">
          <td></td>
          <td>{{ parseFloat(feesTotal) }}</td>
          <td>{{ parseFloat(transactionsTotal) }}</td>
          <td>{{ parseFloat(remainTotal) }}</td>
        </tr>
      </thead>
      <thead>
        <tr
          class="text-center text-light"
          style="background-color: #2a3042 !important"
        >
          <th>{{ $t("bills_payable.name") }}</th>
          <th>{{ $t("bills_payable.fees") }}</th>
          <th>{{ $t("bills_payable.transactionTotal") }}</th>
          <th>{{ $t("bills_payable.remain") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="bill in billsPayable" :key="bill">
          <td>{{ bill.name }}</td>
          <td>{{ bill.fees }}</td>
          <td>{{ bill.transactionTot }}</td>
          <td>{{ bill.remain }}</td>
        </tr>
      </tbody>
    </table>
  </Layout>
</template>
<style scoped>
/*
.table,
td {
  border: 1px solid black;
}
*/
</style>
